<template>
  <div class="transfer-client-owner">
    <!-- 发布 -->
    <el-dialog
      :visible.sync="transferOwnerDialog"
      :title="$t('pagecreator_button_publish')"
      top="15%"
      width="45%"
      :close-on-click-modal="false"
      :before-close="releasehandleClose"
    >
      <p style="margin-bottom: 10px">
        <!-- 将删除任何分配详细信息。 -->
        {{ $t("label.knowledgebase.delete.details") }}
      </p>
      <div style="margin-bottom: 10px">
        <!-- 现在发布 -->
        <el-radio v-model="radio" label="1" @change="changestate">
          {{ $t("label.knowledgebase.publish.now") }}</el-radio
        >
      </div>
      <div style="margin-bottom: 10px">
        <!-- 计划发布 -->
        <el-radio v-model="radio" label="2">{{
          $t("label.knowledgebase.plan.release")
        }}</el-radio>
      </div>
      <div class="block" style="margin-bottom: 10px">
        <!-- 选择日期 -->
        <el-date-picker
          v-model="value1"
          type="date"
          v-if="radio == '1'"
          disabled
          :placeholder="$t('label_tabpage_selectdate')"
          :picker-options="pickerOptions0"
        >
        </el-date-picker>
        <el-date-picker
          v-model="value1"
          type="date"
          value-format="yyyy-MM-dd"
          v-else
          :placeholder="$t('label_tabpage_selectdate')"
          :picker-options="pickerOptions0"
        >
        </el-date-picker>
      </div>
      <div v-show="markNewVersion == 'true'">
        <el-checkbox
          v-model="newchecked"
          style="margin-right: 15px"
        ></el-checkbox>
        <!-- 标记为新版本 -->
        {{ $t("label.knowledgebase.mark.as.new.version") }}
      </div>
      <!-- <div v-else>
        <el-checkbox
          v-model="newchecked"
          disabled
          style="margin-right: 15px"
        ></el-checkbox
        >标记为新版本
      </div> -->

      <div v-show="affectTranslation == 'true'">
        <p style="font-size: 14px; color: #080707">
          <!-- 更改是否会影响本文章的现有翻译？如果这样，您可以将之前的翻译标记为“过期”。这样，您可以生成需要翻译供应商更新的文章列表。 -->
          {{ $t("label.knowledgebase.affect.article.version") }}
        </p>
      </div>
      <div
        style="font-size: 14px; color: #080707"
        v-show="affectTranslation == 'true'"
      >
        <div style="margin-top: 15px; margin-bottom: 15px">
          <el-radio v-model="translateradio" label="1">
            <!-- 是，我的更改会影响现有翻译。标记为“过时”。 -->
            {{ $t("label.knowledgebase.affect.existing.translations") }}
          </el-radio>
        </div>
        <div>
          <el-radio v-model="translateradio" label="2">
            <!-- 否，我的更改不会影响现有翻译。 -->
            {{ $t("label.knowledgebase.noaffect.existing.translations") }}
          </el-radio>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="releasehandleClose" size="mini">
          <!-- 取消 -->
          {{ $t("label.cancel") }}
        </el-button>
        <el-button @click="Releasesure" type="primary" size="mini">
          <!-- 发布 -->
          {{ $t("pagecreator_button_publish") }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script type="text/ecmascript-6">
import * as knowledgearticlesApi from "./api.js";
export default {
  components: {},
  props: {
    id: {
      type: String,
      default: "",
    },
    ids: {
      type: String,
      default: "",
    },
    transferOwnerDialog: {
      type: Boolean,
      default: false,
    },
    // 对象id
    objId: {
      type: String,
      default: "",
    },
    // 是否显示”是否会影响翻译”
    markNewVersion: {
      type: String,
      default: "false",
    },
    // 是否显示”标记为新版本”
    affectTranslation: {
      type: String,
      default: "false",
    },
    jumpsign: {
      type: Boolean,
    },
  },
  data() {
    return {
      radio: "1", //发布单选
      translateradio: "1",
      value1: "", //发布时间选择
      newchecked: true,
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() < Date.now();
        },
      }, //日期选择器禁用今天之前(不包含今天)
      // pickerOptions0: {
      //   disabledDate(time) {
      //     return time.getTime() < Date.now() - 8.64e7;
      //   },
      // },//日期选择器禁用今天之前(包含今天)
    };
  },
  methods: {
    releasehandleClose() {
      this.$emit("releasehandleClose");
    },
    changestate() {
      this.value1 = "";
    },
    Releasesure() {
      var data = [];
      if (this.id) {
        data.push({ id: this.id });
      } else {
        let arr = this.ids.split(",");
        arr.forEach((item) => {
          data.push({ id: item });
        });
      }
      let params = {
        data: JSON.stringify(data),
        publishType: this.radio == "1" ? "realTime" : "timing",
        publishDate: this.radio !== "1" ? this.value1 : "",
        markNewVersion: this.markNewVersion,
        affectTranslation: this.affectTranslation,
      };
      this.releasehandleClose();
      knowledgearticlesApi.publishArticle(params).then((res) => {
        if (res.data.succeedCount > 0) {
          // setTimeout(() => {
          // this.$message.success(res.data.succeedCount + "条数据发布成功！");
          this.$message.success(
            res.data.succeedCount +
              this.$i18n.t("label.knowledgebase.data.published.successfully", [
                res.data.succeedCount,
              ])
          );
          // }, 1000);
          if (res.data.failCount == 0) {
            this.$emit("getSingleGlobalResult");
          }
        }
        if (res.data.failCount > 0) {
          setTimeout(() => {
            this.$message.error(
              res.data.failCount + "条数据发布失败,不符合发布条件不能发布！"
            );
          }, 1000);
        }
        this.$emit("refreshall");
        this.$emit("refreshright");
        this.$emit("refreshpage");
        // this.$message({
        //   message: "发布成功",
        //   type: "success",
        // });
      });
      this.radio = "1"; //发布单选
      this.translateradio = "1";
      this.value1 = ""; //发布时间选择
      this.newchecked = true;
    },
  },
};
</script>
<style scoped lang="scss">
</style>
